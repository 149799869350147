// this file will not make it to browsers unless you include it in a build target - see assets.json. E.g.:
//
//      "main.js": {
//          "main": true,
//          "files": [
//              "scripts/example.js"
//          ]
//      }


(function($) {

    console.log('Example script output');


})(jQuery);