
(function($) {
    function handler(event) {
        if($(window).scrollTop() > 50) {
            $('header').addClass('scrolled');
        } else {
            $('header').removeClass('scrolled');
        }
    }

    function frameHandler() {
        handler();
        requestAnimationFrame(frameHandler);
    }

    requestAnimationFrame(frameHandler);

})(jQuery, window);