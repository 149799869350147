;(function($) {
    var scopedSelector = '.js-collapsible-sidebar';
    $(scopedSelector + '.commerce-sidebar').on('click', 'h3', function (event) {
        togglePanel($(this));
    });

    $('.commerce-sidebar__title').click(function (event) {
        if (Modernizr.mq('(max-width: 767px)')) {
            togglePanel($(this));
            updateWidgetUi();
        }
    });

    var togglePanel = function ($panelTitle) {
        var visible = $panelTitle.next().is(":visible");
        $panelTitle.parent().toggleClass('expanded', !visible);
        $panelTitle.parent().toggleClass('collapsed', !!visible);
        $panelTitle.next().slideToggle();
    }

    var initToggleStates = function () {
        $(scopedSelector + '.commerce-sidebar .widget').each(function (i, el) {
            var $widget = $(el);
            if (!widgetIsFilter($widget)) return;
            if (!filterWidgetHasSelection($widget)) {
                $widget.find('ul').slideUp(0);
            } else {
                $widget.find('ul').slideDown(0);
            }
        });
    }

    var getWidgetByTitle = function (title) {
        return $(scopedSelector + '.commerce-sidebar h3:contains("' + title + '")').parent();
    }

    var widgetIsFilter = function ($el) {
        return $el.hasClass('widget_layered_nav');
    }

    var filterWidgetHasSelection = function ($el) {
        return $el.find('.current-cat, .chosen').length > 0;
    }

    var updateWidgetUi = function () {
        updateUi(scopedSelector + '.commerce-sidebar h3');
    }

    var updateUi = function (selector) {
        $(selector).each(function (i, el) {
            var el = $(el);
            var visible = el.next().is(":visible");
            el.parent().toggleClass('expanded', !!visible);
            el.parent().toggleClass('collapsed', !visible);
        });
    }

    var disableDependentFilters = function () {
        var makeWidget = getWidgetByTitle("Make");
        var seriesWidget = getWidgetByTitle("Series");
        var modelWidget = getWidgetByTitle("Model");
        if (!filterWidgetHasSelection(makeWidget)) {
            modelWidget.find('ul li').detach();
            modelWidget.find('ul').append('<li>Please select a make first</li>');
            seriesWidget.find('ul li').detach();
            seriesWidget.find('ul').append('<li>Please select a make first</li>');
        }
    }

    initToggleStates();
    updateWidgetUi();
    disableDependentFilters();
})(jQuery);