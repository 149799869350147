(function($){
  $('.search-bar .icon-search').on('click', function(event) {
    $(this).closest('.search-bar').addClass('search-active');
    $(this).closest('.search-bar').find('input[type=search]').focus().select();
  });

  $('.search-bar input').on('blur', function(event) {
    if(event.relatedTarget != $('.search-bar .summary-icon').get(0) && $(this).val() == '') {
      $(this).closest('.search-bar').removeClass('search-active');
    }
    if(event.relatedTarget == $('.search-bar .summary-icon').get(0) && $(this).val() !== '') {
      $(this).closest('.search-bar').find('form').submit();
    }
  });
})(jQuery);